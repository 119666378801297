var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "reviewFormDialog",
      staticClass: "audit-form",
      attrs: { model: _vm.reviewForm, rules: _vm.reviewRules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "审核状态", prop: "auditStatus" } },
        [
          _c(
            "el-radio-group",
            {
              on: { change: _vm.onAuditStatusChange },
              model: {
                value: _vm.reviewForm.auditStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.reviewForm, "auditStatus", $$v)
                },
                expression: "reviewForm.auditStatus",
              },
            },
            [
              _c(
                "el-radio",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "1" },
                },
                [_vm._v("通过")]
              ),
              _c(
                "el-radio",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "2" },
                },
                [_vm._v("不通过")]
              ),
              _c(
                "el-radio",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "3" },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
          _vm.reviewForm.auditStatus == "3"
            ? _c(
                "el-select",
                {
                  staticStyle: { "margin-left": "10px" },
                  model: {
                    value: _vm.reviewForm.backStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.reviewForm, "backStatus", $$v)
                    },
                    expression: "reviewForm.backStatus",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: -1, label: "返回填报人修改" },
                  }),
                  _vm._l(_vm.backList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { value: item.status, label: item.name },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.reviewForm.auditStatus == "2"
            ? _c("el-alert", {
                staticStyle: { "line-height": "20px" },
                attrs: {
                  title:
                    "请慎用“不通过”操作。不通过后，该“记录/项目”将被终止。填报人无法修改。若想让填报人修改此数据，请选择“返回修改”，填报人可以有一次修改机会。",
                  type: "warning",
                  "show-icon": "",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { "line-height": "0px" },
          attrs: { label: "审核意见", prop: "reviewRemark" },
        },
        [
          _c("el-input", {
            staticStyle: { "margin-bottom": "2px" },
            attrs: { type: "textarea", placeholder: "请输入审核意见" },
            model: {
              value: _vm.reviewForm.reviewRemark,
              callback: function ($$v) {
                _vm.$set(_vm.reviewForm, "reviewRemark", $$v)
              },
              expression: "reviewForm.reviewRemark",
            },
          }),
        ],
        1
      ),
      _vm.formDetail.auditAuthSign
        ? _c(
            "el-form-item",
            { attrs: { label: "审核签字", prop: "reviewSign" } },
            [
              _c("sign", {
                ref: "signRef",
                attrs: {
                  userId: _vm.loginUserId,
                  agencyId: _vm.formDetail.agencyId,
                  fillId: _vm.reviewForm.fillId,
                  formId: _vm.formDetail.formId,
                  status: _vm.reviewForm.status,
                },
                model: {
                  value: _vm.reviewForm.reviewSign,
                  callback: function ($$v) {
                    _vm.$set(_vm.reviewForm, "reviewSign", $$v)
                  },
                  expression: "reviewForm.reviewSign",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticStyle: { height: "20px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }