<template>
    <el-form ref="reviewFormDialog" :model="reviewForm" :rules="reviewRules" class="audit-form">
          <el-form-item label="审核状态" prop="auditStatus">
            <el-radio-group v-model="reviewForm.auditStatus" @change="onAuditStatusChange">
              <el-radio label="1" style="margin-bottom: 0">通过</el-radio>
              <el-radio label="2" style="margin-bottom: 0">不通过</el-radio>
              <el-radio label="3" style="margin-bottom: 0">返回</el-radio>
            </el-radio-group>
             <el-select v-model="reviewForm.backStatus" v-if="reviewForm.auditStatus=='3'" style="margin-left:10px;">
                <el-option :value="-1" label="返回填报人修改"></el-option>
                <el-option :value="item.status" :label="item.name" v-for="(item,index) in backList" :key="index"></el-option>
            </el-select>
            <el-alert v-if="reviewForm.auditStatus == '2'" title="请慎用“不通过”操作。不通过后，该“记录/项目”将被终止。填报人无法修改。若想让填报人修改此数据，请选择“返回修改”，填报人可以有一次修改机会。" style="line-height:20px;" type="warning" show-icon></el-alert>
          </el-form-item>
          <el-form-item label="审核意见" prop="reviewRemark" style="line-height:0px;">
            <el-input type="textarea" v-model="reviewForm.reviewRemark" placeholder="请输入审核意见" style="margin-bottom:2px;"></el-input>
          </el-form-item>
          <el-form-item label="审核签字" prop="reviewSign" v-if="formDetail.auditAuthSign">
            <!-- 审核签名 -->
            <sign ref="signRef" :userId="loginUserId" :agencyId="formDetail.agencyId" :fillId="reviewForm.fillId" :formId="formDetail.formId" :status="reviewForm.status" v-model="reviewForm.reviewSign"></sign>
          </el-form-item>
          <div style="height:20px"></div>
        </el-form>
</template>
<script>
import sign from "@/views/system/form/components/sign/auditSignPC.vue";
import { auditBackList } from '@/api/system/form.js'
export default{
    components: {sign},
    props:{
        reviewForm:{
            type:Object,
            default: ()=>{}
        },
        formDetail:{
            type:Object,
            default: ()=>{}
        },
        loginUserId:{
            type:Number
        },
        isAllDataSelected:{
            type: Boolean,
            default: false
        },
        fillCacheKey:{
            type: String,
            default: '',
        }, 
        selectedRowKeys:{
            type: Array|Object,
            default: ()=>[]
        }, 
        excludeIds:{
            type: Array,
            default: ()=>[]
        }
    },
    data(){
        return {
            reviewRules: {
                auditStatus: [
                    {
                    required: true,
                    message: "审核状态不能为空",
                    trigger: ["blur", "change"],
                    }
                ]
            },
            backList:[]
        }
    },
    created(){
        this.onAuditStatusChange();
    },
    methods:{
        onAuditStatusChange(){
            if(this.reviewForm.auditStatus=='3'){
                this.$set(this.reviewForm,'backStatus', -1);
                this.getAuditBackList();
            }
        },
        getAuditBackList(){
            auditBackList({
                formId:this.formDetail.formId,
                fillList: Array.isArray(this.selectedRowKeys)? this.selectedRowKeys: [this.selectedRowKeys],
                type: this.isAllDataSelected?1:0,
                excludeFillIds: this.excludeIds.join(','),
                fillCacheKey: this.isAllDataSelected?this.fillCacheKey:this.fillCacheKey
            }).then(res=>{
                this.backList = res.data
            })
        },
        validate(callback){
            return this.$refs.reviewFormDialog.validate(callback)
        },
        resetFields(){
            return this.$refs.reviewFormDialog.resetFields()
        },
        clearValidate(){
            return this.$refs.reviewFormDialog.clearValidate()
        },
        async uploadSign(){
            return this.$refs.signRef.promiseUpload();
        }
    }
}
</script>
<style scoped lang="scss">
.audit-form {
  .el-form-item__label {
    line-height: 40px !important;
  }
}

</style>