var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        staticClass: "signature-box",
        class: [!!_vm.fieldModel ? "" : "empty"],
        style: { backgroundImage: "url(" + _vm.signPicture + ")" },
        on: { click: _vm.showSign },
      }),
      _c(
        "div",
        { staticStyle: { left: "0px", height: "30px", position: "relative" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                top: "0px",
                left: "-16px",
                height: "100%",
                width: "100%",
              },
            },
            [
              _c("useMobileSignTipVue", {
                attrs: { formId: _vm.formId, fillId: _vm.fillId },
                on: { update: _vm.mobileSignUpdate },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              attrs: {
                title: "审核签字",
                width: "650px",
                visible: _vm.dialogVisible,
                "show-close": true,
                "custom-class": "signature-dialog",
                "append-to-body": true,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "destroy-on-close": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "signature",
                  staticStyle: { position: "relative" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "signature-tool",
                      staticStyle: {
                        display: "flex",
                        position: "absolute",
                        right: "0px",
                        "z-index": "11",
                      },
                    },
                    [
                      !_vm.disabled
                        ? _c(
                            "div",
                            {
                              staticClass: "signature-tool-item",
                              staticStyle: {
                                padding: "2px 10px",
                                border: "solid 1px #aaaaaa",
                                cursor: "pointer",
                              },
                              on: { click: _vm.clearSignature },
                            },
                            [_vm._v(" 清空 ")]
                          )
                        : _vm._e(),
                      !_vm.disabled
                        ? _c(
                            "div",
                            {
                              staticClass: "signature-tool-item",
                              staticStyle: {
                                padding: "2px 10px",
                                border: "solid 1px #aaaaaa",
                                cursor: "pointer",
                              },
                              on: { click: _vm.undoSignature },
                            },
                            [_vm._v(" 撤销 ")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("VueSignaturePad", {
                    ref: "signaturePad",
                    attrs: { options: _vm.options },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  !_vm.disabled
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "large", type: "primary" },
                          on: { click: _vm.saveSign },
                        },
                        [_vm._v("确定")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "large" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.dialogVisibleMobile
        ? _c(
            "div",
            { staticClass: "signature-dialog-mobile window-style1" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("审核签字")]),
              _c("VueSignaturePad", {
                ref: "signaturePadMobile",
                staticStyle: {
                  border: "dashed 2px #aaaaaa",
                  "box-sizing": "content-box",
                },
                attrs: {
                  width: _vm.signatureWidth,
                  height: _vm.signatureHeight,
                  options: _vm.options,
                },
              }),
              _c("div", { staticClass: "signature-tool" }, [
                _c("div", { staticStyle: { display: "inline-block" } }, [
                  !_vm.disabled
                    ? _c(
                        "div",
                        {
                          staticClass: "signature-tool-item",
                          on: { click: _vm.clearSignatureMobile },
                        },
                        [_vm._v(" 清空 ")]
                      )
                    : _vm._e(),
                  !_vm.disabled
                    ? _c(
                        "div",
                        {
                          staticClass: "signature-tool-item",
                          on: { click: _vm.undoSignatureMobile },
                        },
                        [_vm._v(" 撤销 ")]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    !_vm.disabled
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.saveSignMobile },
                          },
                          [_vm._v("确定")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.dialogVisibleMobile = false
                          },
                        },
                      },
                      [_vm._v("关闭")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }